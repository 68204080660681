import 'react-native-get-random-values';
import { v4 as uuidv4 } from 'uuid';

export const data = {
  "brand": "Intellipoint Corporation",
  "article": {    
    "backdrop": "/img/bg10.jpg",
    "title": "A 15 Minute Introduction to ChatGPT",
    "card": {
      "info": "AI",
      "alt": "Artificial Intelligence and Machine Learning News",
      "backdrop": "/img/openai.webp",
      "content": [
        {
          "id": uuidv4(),
          "subHeader": "What is ChatGPT?",
          "link": "gpt",
          "description": `ChatGPT (Generative Pre-trained Transformer) is a chatbot launched 
          by OpenAI in November 2022. It is built on top of OpenAI's GPT-3.5 family of large 
          language models, and is fine-tuned with both supervised and reinforcement learning techniques. 
          ChatGPT was launched as a prototype on November 30, 2022, and quickly garnered attention for 
          its detailed responses and articulate answers`
        },
        {
        "id": uuidv4(),  
        "subHeader": "Step 1. Goto OpenAI.com",
        "link": "#info",
        "description": `Go to <a href=\"https://openai.com\" target=\"_blank\">OpenAI</a>, 
        this is the company that has created ChatGPT. 
        First, You need to create an account in order to get access to the api resources. 
        <br/>
        After setting up your account, head over to <a href="https://chat.openai.com" target="_blank">Chat Open AI</a>.`,          
        "img": {
          "src": "/img/openai.jpg",
          "alt": "OpenAI Website"
          },
        },
        {
          "id": uuidv4(),  
          "subHeader": "Quickstart",
          "link": "#codebase",
          "description": `OpenAI is really great at language models. It can understand 
          and generate, for the most part, competent responses. 
          The API provides access to these models. Using these, it can solve any 
          task that involves processing natural and computer language.
          <br/>
          A high-level list of capabiltiies include:
          <ul>
            <li>Content generation</li>
            <li>Summarization</li>
            <li>Classification, categorization and sentiment analysis.</li>
            <li>Data extraction</li>
            <li>Translations</li>
          </ul>`          
        },
        {
          "id": uuidv4(),  
          "subHeader": "Using the Completions Endpoint.",
          "link": "#completion",
          "description": `The completion endpoint is the core of OpenAI's API and it provides a intuitive and powerful interface.
          It eseentially takes autocompletion to a whole other level. The idea is you provide text as a prompt, and the API will return
          a text completion that attempts to match whatever instructions or context you gave it.<br/><br/>
          
          As you might expect, the more specific your prompt, the better answer to the question you'll receive.
          In addition, the completion api has settings that you can customize general responses. You can configure this
          by using the temperature setting defined on its prompt. 
          
          <br/><br/>
          Think of temperature of the degree of randomness. Temperature currently is set between 0 and 1. The lower the number,
          the more narrowed the response. 
          For example, a temperature of 0 will produce the exact same answers to the question if repeated multiple times. 
          On the other hand, if the temperature is set to 1, you'll receive different answers each time.

          <br/><br/>
          Probably, you're asking how does this work? The answer is based on tokens and probabilities. Models are processed 
          into smaller units called tokens. Tokens can be words, phrases or even single characters. 
          The prompt or question is taken and broken down. Common words or nouns are classified. Larger and more uncommon words
          are broken further down. Each classification is then calculated by a probability metric. The higher the metric, the more likely the
          correct answer. Temperature allows the variance defined on that answer.
          `,
          "img": {
            "src": "/img/bar.jpg",
            "alt": "ChatGPT passed the bar"
          },          
        },
        {
          "id": uuidv4(),  
          "subHeader": "App Setup",
          "link": "#chatApp",
          "description": `Lets write a simple app. In this article, we'll use node. To get started, you'll need:
          <ul>
            <li><a href="https://github.com" target="_blank">git</a></li>  
            <li><a href="https://nodejs.org/en/" target="_blank">node</a></li>            
          </ul>
          Clone the project with this:
          <pre>
            git clone https://github.com/openai/openai-quickstart-node.git
          </pre>
          `          
        },
        {
          "id": uuidv4(),  
          "subHeader": "Create An API Key",
          "link": "#apiKey",
          "description": `As you might suspect, you need an api key. You can generate your key from the <a href="https://beta.openai.com/docs/quickstart/build-your-application" target="_blank">docs.</a>
          And, it goes without saying, protect your key. Don't expose keys like this on the client-side, right? If not, wait on that bitcoin app idea. ;)`,
          "img": {
            "src": "/img/apiKey.jpg",
            "alt": "Generating an API Key"
          },          
        }, 
        {
          "id": uuidv4(),  
          "subHeader": "Setup",
          "link": "#setup",
          "description": `Like a normal project, it comes down to a few steps. First, we install.
          <pre>npm install</pre>
          <br/>
          Next, you'll find a .env.example file. Copy or move the file to the standard file name <strong>.env</strong>. 
          <br/>
          Take the key you generated from the site here:`,
          "img": {
            "src": "/img/env.jpg",
            "alt": "Generating an API Key"
          },                  
        },
        {
          "id": uuidv4(),  
          "subHeader": "Running In Dev Mode",
          "link": "#infra",
          "description": `Now with the setup being complete, you can run the app with the following command.
          <br/>
          <pre>
            npm run dev
          </pre>
          The app should start up and be available at http://localhost:3000/ by default.
          `          
        },
        {
          "id": uuidv4(),  
          "subHeader": "Customizing the Default App",
          "link": "#customize",
          "description": `The default app is helpful for naming a superhero animal. Cool but how but we use it for something a bit more practical. 
          <br/>  
          Let's say your entrepreneur and you want to launch a product or need a name for a business. Suppose you are a content author and want to automate alot of it.           
          Perhaps, you want to write a resume or have something keep it up to date.                     
          I hope your brain is going crazy right now with all the applications you can use.
          <br/><br/>

          Let's create an app that can generate a business name for us. <br/> 
          We'll use the sample app to modify this.
          `,          
        },
        {
          "id": uuidv4(),  
          "subHeader": "The Default App Structure",
          "link": "#app",
          "description": `There are two files of interest. The app is a front-end React page is found in index.js and an api endpoint exposed in generate.js.
          <br/>          
          `,
          "img": {
            "src": "/img/structure.jpg",
            "alt": "Application Structure"
          },          
        }, 
        {
          "id": uuidv4(),  
          "subHeader": "Modify The React Front-End",
          "link": "#react",
          "description": `After experimenting with the api for a bit, you may notice that when you ask a question that has more than one answer, the answer is rendered on the same
          line. Let's update it render the content a seperate lines. 
          <br/><br/>
            Let's update the declaration of the result variable to the following:
            <pre>
              7  const [results, setResults] = useState([]);
            </pre>
            Next, rather than dealing with animals, lets update the ui to response to generic questions rather than forcing a specific type of answer. Keep in mind, it really depends on 
            the use case you are looking to solve. Now, let's replace all occurences of animal and set animal with these variables
            <pre>
               6   const [question, setQuestion] = useState("");
            </pre>
            Next, let's update the onSubmit question to handle dealing with the api responses.
            <pre>
              17    body: JSON.stringify({ 'question': question }),
              ...
              24    const answers = (data.result) ? data.result.split("\\n") : "Not Available"; 
            </pre>
            Finally, we need to update jsx to render multiple answers using the map property:
            <pre>
                <div className={styles.result}>{results.map((item) => 
                  <p>{item}</p>)} 
                </div>
            </pre>

          <br/>          
          `,        
        },
        {
          "id": uuidv4(),  
          "subHeader": "Modifying the OpenAI API",
          "link": "#api",
          "description": `First order of business here is to modify the request to inspect for the question object we are passing from the front-end.
          <pre>
              const question = req.body.question || '';
              if (question.trim().length === 0) {
                res.status(400).json({
                  error: {
                    message: "Please enter a valid question",
                  }
                });
                return;
              }
          </pre>
          Now, we are looking for the right object. Next, we can update the generatePrompt function because we are passing the value as is into the createCompletion
          api endpoint. At this point, it is a placeholder if we wanted to customized the input with more search criteria, however, for this exerice, we pass it as is:
          <pre>
            function generatePrompt(question) {  
              return question;
            }          
          </pre>
          We can change the prompt api call object to look like this:
          <pre>
              const completion = await openai.createCompletion({
                model: "text-davinci-003",
                prompt: generatePrompt(question),
                temperature: 0.6,
                max_tokens: 256,
                top_p: 1,
                frequency_penalty: 0,
                presence_penalty: 0,
              });
          </pre>    
          <br/> 
          We now have everything in place. Ask a question and watch it wonder.         
          `          
        },
        {
          "id": uuidv4(),  
          "subHeader": "Conclusion",
          "link": "#api",
          "description": `This is a really quick primer of what OpenAI api can do. It opens the doors for developing applications that take question based interactions to a higher degree
          of automation. With these in place, it will learn more and more over time and grow into a more intelligent system.
          <br/><br/>
          Here's are the answers to a question I asked it. Check out the answers, not too shabby. <br/>`,          
          "img": {
            "src": "/img/lifeCoach.jpg",
            "alt": "Life Coach App"
          },          
        }
      ]
    },
        
  }
}