import 'react-native-get-random-values';
import { v4 as uuidv4 } from 'uuid';

export const data = {
  "brand": "Intellipoint Corporation",
  "article": {    
    "backdrop": "/img/bg12.webp",
    "title": "Privacy Policy",
    "card": {
      "info": "Our Agreement To You",
      "alt": "Our Agreement To You",      
      "content": [
        {
          "id": uuidv4(),  
          "subHeader": "What Types of Personal Information Do We Collect?",
          "link": "#collect",
          "description": `
          Contact Information like your email address, telephone number, postal address and contact information of any referrals, references or emergency contacts you provide.
          <br/>
          <br/>
          <strong>Professional and Work Eligibility Information:</strong> including your resume or C.V., employment history, education history, certifications, professional license information (for example bar registration number), languages proficiencies, work authorization, professional association memberships, volunteer activities, occupational health and safety information where required, client information where relevant (for example we may collect information from attorneys we place through our staffing and search business regarding clients the attorney has performed services for) and work-related skills, practice details and skills assessment information including personality characteristics / traits which you choose to provide on a voluntary basis.
          <br/>
          <br/>
          <strong>Career Planning Information:</strong> including your career interests, desired compensation and benefits, desired work location and willingness to relocate, family information as it relates to your career goals or plans, and hobbies and interests.
          <br/><br/>
          <strong>Demographic Information:</strong> including your race, gender, national origin, disability status and veteran status, where we are required to or permitted to do so by law and in support of equal opportunity monitoring or affirmative action programs.
          <br/><br/>
          <strong>Photos and Visual Images:</strong> including photos that you provide to us or that you make publicly available on the internet (for example LinkedIn or company/firm websites); your image on video if you elect to do a video interview, and your image from a security camera if you visit one of our locations.
          <br/><br/>
          <strong>Device Information: </strong> including your IP address, browser type and version, time zone setting, browser plug-in types and versions, location information on a city-regional basis and operating system and platform information when you are visiting our Site(s).
          <br/><br/>
          <strong>Other Information You Provide:</strong> including any other Personal Information you may elect to provide to us, including any paper materials you submit to us.`,          
        },
        {
          "id": uuidv4(),  
          "subHeader": "Do We Collect Sensitive Personal Information?",
          "link": "#no-pii",
          "description": `We do not collect any information from you that would be considered Sensitive Personal Information in California or Virginia.  
          <br/><br/>
          Under privacy laws in both California and Virginia, Sensitive Personal Information includes the following:  racial or ethnic origin, religious or philosophical beliefs, mental or physical health condition, sexual orientation/sex life, genetic data, biometric data and precise geolocation.  Under Virginia’s privacy law, Sensitive Personal Information also includes immigration or citizenship status.  Under California’s privacy law, Sensitive Personal Information also includes union membership, driver’s license number, Social Security Number (SSN), state ID numbers, passport numbers and the contents of a consumer’s text, mail and email (not directed to us).`,          
        }, 
        {
          "id": uuidv4(),  
          "subHeader": "Who Do We Disclose Your Personal Information To?",
          "link": "#nobody",
          "description": `<strong>We never market, share or trade your personal information.</strong> `,          
        },       
      ]
    }    
  }
}