/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";


// react components used to create a google map
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import PinDrop from "@material-ui/icons/PinDrop";
import Phone from "@material-ui/icons/Phone";
import Email from '@material-ui/icons/Email';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import DefaultHeader from "components/DefaultHeader/DefaultHeader.js";

import contactUsStyle from "assets/jss/material-kit-pro-react/views/contactUsStyle.js";
import DefaultFooter from "components/DefaultFooter/DefaultFooter";

import {isEmpty} from 'ramda';

import { useMutation, gql } from "@apollo/client";

const CustomSkinMap = withScriptjs(
  withGoogleMap(() => (
    <GoogleMap
      defaultZoom={14}
      defaultCenter={{ lat: 28.4492258, lng: -81.4837206 }}
      defaultOptions={{
        scrollwheel: false,
        zoomControl: true,
        styles: [
          {
            featureType: "water",
            stylers: [
              { saturation: 43 },
              { lightness: -11 },
              { hue: "#0088ff" }
            ]
          },
          {
            featureType: "road",
            elementType: "geometry.fill",
            stylers: [
              { hue: "#ff0000" },
              { saturation: -100 },
              { lightness: 99 }
            ]
          },
          {
            featureType: "road",
            elementType: "geometry.stroke",
            stylers: [{ color: "#808080" }, { lightness: 54 }]
          },
          {
            featureType: "landscape.man_made",
            elementType: "geometry.fill",
            stylers: [{ color: "#ece2d9" }]
          },
          {
            featureType: "poi.park",
            elementType: "geometry.fill",
            stylers: [{ color: "#ccdca1" }]
          },
          {
            featureType: "road",
            elementType: "labels.text.fill",
            stylers: [{ color: "#767676" }]
          },
          {
            featureType: "road",
            elementType: "labels.text.stroke",
            stylers: [{ color: "#ffffff" }]
          },
          { featureType: "poi", stylers: [{ visibility: "off" }] },
          {
            featureType: "landscape.natural",
            elementType: "geometry.fill",
            stylers: [{ visibility: "on" }, { color: "#b8cb93" }]
          },
          { featureType: "poi.park", stylers: [{ visibility: "on" }] },
          {
            featureType: "poi.sports_complex",
            stylers: [{ visibility: "on" }]
          },
          { featureType: "poi.medical", stylers: [{ visibility: "on" }] },
          {
            featureType: "poi.business",
            stylers: [{ visibility: "simplified" }]
          }
        ]
      }}
    >
      <Marker position={{ lat: 44.43353, lng: 26.093928 }} />
    </GoogleMap>
  ))
);

const useStyles = makeStyles(contactUsStyle);

export default function ContactUsPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const [name, setName] = React.useState('');
  const [emailAddress, setEmailAddress] = React.useState('');
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [enabled, setEnabled] = React.useState(false);
  const [showNameError, setShowNameError] = React.useState(false);
  const [showEmailAddressError, setShowEmailAddressError] = React.useState(false);
  const [showPhoneNumberError, setShowPhoneNumberError] = React.useState(false);
  const [showMessageError, setShowMessageError] = React.useState(false);
  const PHONE_REGEX = new RegExp('^\\(?([0-9]{3})\\)?[-.\\s]?([0-9]{3})[-.\\s]?([0-9]{4})$');

  const mutation = gql`
    mutation CreateContact($contactInput: ContactInput!){
      createContact (contactInput: $contactInput) {    
        name
        emailAddress
        phoneNumber    
    }
  }
  `;


  //inject the payload into a graph input var  
  const [muto, { data, loading, error, reset }] = useMutation(mutation);

  const validateForm = () => {
    const isNameError = isEmpty(name);
    const isEmailError = isEmpty(emailAddress);
    const isPhoneNumberError = isEmpty(phoneNumber);
    const isMessageError = isEmpty(message);
    
    setShowNameError(isNameError);
    setShowEmailAddressError(isEmailError);
    setShowPhoneNumberError(isPhoneNumberError);
    setShowMessageError(isMessageError);    
  }

  const hasEmptyField = () => {
    return setEnabled(isEmpty(name) && isEmpty(emailAddress) && isEmpty(phoneNumber) && isEmpty(message));
  }

  const handleSubmitForm = async (e) => {
    e.preventDefault();    
    
    validateForm();
    if(hasEmptyField()) {
      return; 
    }
    
    if(!emailAddress.includes('@') 
        && (!emailAddress.includes('.com') ||
             !emailAddress.includes('.org') ||
               !emailAddress.includes('.edu'))) {
      setShowEmailAddressError(true);
      return;
    }

    if(!PHONE_REGEX.test(phoneNumber)) {
      setShowPhoneNumberError(true);
      return;
    }

    // create the payload         
    try {
      // construct the graphql endpoint and invoke it.
      const result = await muto({
        variables: {
          "contactInput": {  
            "name": name,
            "emailAddress": emailAddress,
            "phoneNumber": phoneNumber,
            "message": message
          }
        }    
      });
      // redirect to confirmation page.
      if(result.data) {
        console.log('result', result.data);
        localStorage.setItem('name', name);
        localStorage.setItem('emailAddress', emailAddress);
        localStorage.setItem('phoneNumber', phoneNumber);
        localStorage.setItem('message', message);
        window.location.href=`/confirmation`
      }  
    } catch(err) {
      console.error('data',data);
      console.error('loading', loading);
      console.error('error', error);
      console.error('reset', reset);      
    }            
  };

  //1. save the data to the react vars
  const handleChange = (e) => {
    switch(e.target.name) {
      case 'name':                
        setName(e.target.value);
        break;
      case 'emailAddress':        
        setEmailAddress(e.target.value);
        break;
      case 'phoneNumber':        
        setPhoneNumber(e.target.value);
        break;
      default:        
        setMessage(e.target.value);
        break;
    }    
    hasEmptyField();    
  };

  
  return (
    <div>
      <DefaultHeader page="contact-us" />
      {<div className={classes.bigMap}>
        <CustomSkinMap
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDpszBMREs5qNe4Tq_HgsSbkoWfscXbKfY"
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={
            <div
              style={{
                height: `100%`,
                borderRadius: "6px",
                overflow: "hidden"
              }}
            />
          }
          mapElement={<div style={{ height: `100%` }} />}
        />
      </div>}
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.contactContent}>
          <div className={classes.container}>
            <h1 className={classes.title}>Contact Us</h1>
            <GridContainer>
              <GridItem md={6} sm={6}>
                <p>
                  Reach out to us regarding more information on our services, products, patents and license agreements. 
                  <br />
                  <br />
                </p>
                <form onSubmit={async (e) => handleSubmitForm(e)}>                  
                  <CustomInput
                    labelText="* Your Name"
                    id="float"
                    name="name"
                    type="text"
                    onChangeHandler={e => handleChange(e)}
                    error={showNameError}
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                  <CustomInput
                    labelText="* Email address"
                    id="float"
                    name="emailAddress"
                    type="email"
                    error={showEmailAddressError}
                    onChangeHandler={e => handleChange(e)}
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                  <CustomInput
                    labelText="* Phone"
                    name="phoneNumber"
                    type="phone"
                    onChangeHandler={e => handleChange(e)}
                    id="float"
                    error={showPhoneNumberError}
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                  <CustomInput
                    labelText="* Your message"
                    id="float"
                    type="text"
                    onChangeHandler={e => handleChange(e)}
                    error={showMessageError}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      multiline: true,
                      rows: 6
                    }}
                  />
                  <div className={classes.textCenter}>
                    <Button color="primary" round type="submit" disabled={!enabled}>
                      Contact us
                    </Button>
                  </div>
                </form>
              </GridItem>
              <GridItem md={4} sm={4} className={classes.mlAuto}>
                <InfoArea
                  className={classes.info}
                  title="Headquarters"
                  description={
                    <p>
                      7380 West Sand Lake Road <br />  
                      Doctor Phillips,
                      <br /> 
                       Orlando, FL 32819 
                      <br /> 
                      USA
                    </p>
                  }
                  icon={PinDrop}
                  iconColor="primary"
                />                
                <InfoArea
                  className={classes.info}
                  title="Contact Information"
                  description={
                    <p>
                      Intellipoint Corporation <br />  
                      <a href="tel:1-954-881-4633 ">1 (954) 881-4633 </a> 
                      <br />
                    </p>
                  }
                  icon={Phone}
                  iconColor="primary"
                />
                 <InfoArea
                  className={classes.info}
                  title="Contact Information"
                  description={
                    <p>
                      Inquiries & Support <br />  
                      <a href="mailto:mvargas@intellipointcorp.com">Intellipoint Corporation</a> 
                      <br />
                    </p>
                  }
                  icon={Email}
                  iconColor="primary"
                />
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <DefaultFooter />
    </div>
  );
}
