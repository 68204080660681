import 'react-native-get-random-values';
import { v4 as uuidv4 } from 'uuid';

export const data = {
  "brand": "Intellipoint Corporation",
  "article": {    
    "backdrop": "/img/bg1.jpg",
    "placeholder": "/img/bg1-lr.jpg",
    "title": "Developing Stacks Using AWS CDK V2",
    "card": {
      "info": "Technology",
      "alt": " Developing Stacks Using AWS CDK V2",
      "backdrop": "/img/bg14.webp",
      "content": [
        {
          "id": uuidv4(),  
          "subHeader": "Concept",
          "link": "#info",
          "description": `In this series, we’ll create an aws serverless application on the back-end. For this article, we'll give a 
          brief introduction and focus primarily on the design process of creating aws cdk stacks. 
          Our goal is to develop an application that will consist of the 
          following components:
          <ul>
            <li>IAM</li>
            <li>API Gateway</li>
            <li>Lambda</li>
            <li>DynamoDB</li>
          </ul>
          `,          
        },
        {
          "id": uuidv4(),  
          "subHeader": "What is Serverless?",
          "link": "#serverless",
          "description": `As the name implies, a serverless application is one where application servers are not managed directly. 
          Rather, these services are governed for us. From a devsec ops perspective, we are not concerned with the management of the servers, 
          instead, those responsibilities are automatically handled. Because of this, we can concentrate on the instrumentation of this infrastructure.`,          
        },
        {
          "id": uuidv4(),  
          "subHeader": "What is a CDK?",
          "link": "#cdk",
          "description": `AWS CDK stands for Cloud Development Kit and is a framework developed by AWS that allows you to define and provision Cloud 
          infrastructure in code and deploy it through AWS CloudFormation.
          <br/>
          <br/>
          Here is a list of infrastructure that is least likely to change:
          <ul>
            <li>IAM</li>
            <li>DynamoDB</li>
          </ul>
          <br/>
          And here is the infrastructure that is likely to change on a more frequently:
            <ul>
              <li>API Gateway</li>
              <li>Lambda</li>  
            </ul>  
          `,          
        },
        {
          "id": uuidv4(),  
          "subHeader": "Isolating Our Stacks",
          "link": "#isolate",
          "description": `From a management perspective, we will most likely want to have a security stack that houses all potential 
          secret information and an application infrastructure stack that handles the DynamoDB.

          We hit our chatGPT endpoint and ask it what should be the name of our project. We get “Create Corner” as our result so 
          our project is will be “Creative Corner”. 
          
          From this, we’ll derive a naming convention for our stacks using creative-corner as our prefix for all of our stacks.
          <br/>
          <br/>
          <ul>
            <li>creative-corner-security</li>
            <li>creative-corner-infra</li>
            <li>creative-corner-api</li>
          </ul>
          <br/><br/>
          <strong>creative-corner-security</strong>
          <br/>
          <br/>
          Our first stack will be responsible for creating the identity access management for our application. It will create the roles 
          which content the corresponding access policies that will allow the application user to interact with each of these different components 
          which make up the application infrastructure. The application needs to be able to communicate the the following aws services: DynamoDB,
          API Gateway and Lambda. We'll create the following role which can do the follwoing:
          <br/>          
          <br/>
          <strong>creative-corner-role</strong>
          <ul>
            <li>DynamoDB CRUD</li>
            <li>API Invocation</li>
            <li>Lambda Invocation</li>
          </ul>
          <br/>          
          <strong>creative-corner-infra</strong>          
          <br/> <br/>
          This stack will stand up the infrastructure that is isolated to this application. In this example, it is super simple but you could extend 
          the idea to support S3, SQS, SNS, Cognito Pools and so on. For our example, we simply create the DynamoDB component. We’ll also take advantage 
          of an AWS NodeJS Layer to lock down our dependencies.  
          <br/><br/> 
          <strong>creative-corner-api</strong>
          <br/><br/>
          This stack will expose the api gateway and the lambda components. You could debate that the api gateway over time will not really change too much. 
          That said, this is thinking in terms of a greenfield application. 
          `,          
        },
        {
          "id": uuidv4(),  
          "subHeader": "Standardizing Your CDK Code",
          "link": "#standards",
          "description": `If we approach creating a CDK file in a similar fashion that we do with other languages where we can have sections in our files
           that indicate where variables are defined, functions are called, constructs are used and variables are exported. Exported values are a key concept 
           we’ll be using to connect our stacks together. 
          <br/>
          <br/>
          Our files will follow the following conventions:
          <ul>
            <li>Local Variables</li>
            <li>Imports (variables from other Stacks)</li>
            <li>Constructs</li>
            <li>Outputs</li>
          </ul>
          <br/>
          <br/>
          <strong>Local Variables</strong> - we’ll define our stack’s local variable references. Be careful with this as runtime variables are not available. Local Variables 
          should be values known before execution and not values that are calculated from the stack. 
          <br/>
          <br/>
          <strong>Imports</strong> - we’ll use values that are exported from other stacks using the common naming convention prefix. If a lambda requires table access to another variable, 
          we can import that variable from the infra stack and grant permission to the lambda function to interact with it.
          <br/>
          <br/>
          <strong>Constructs</strong> - this section we’ll use to create the amazon resources. This is were we construct the lambda, api gateway, dynamodb 
          and so on.
          <br/>
          <br/>
          <strong>Outputs</strong> - this is where we export the values that will be needed in other stacks. This exposes the values using their logical name 
          rather than the physical id. The difference between the two is that physical id’s are not easily usable from a programmatic resource. They do, however, 
          point to the actual reference of the infrastructure component. Logical Id’s are programmatic friendly and we can use these as pointers to our physicals 
          id’s. If the underlying physical id changes, our logical id reference is updated. This prevents our stacks from quickly referring to outdated components 
          of infrastructure.
          `,          
        },
        {
          "id": uuidv4(),  
          "subHeader": "Identifying Our CDK Stacks",
          "link": "#cdk",
          "description": `If you have already experimented with AWS CDK, you know that the deployment process can be a potential bottleneck when 
          working on fast iteration. Because of that, we want to keep our deployments isolated from one another. If we can isolate infrastructure that 
          seldom changes, then we can place this infrastructure inside a common stack. `,          
        },
        {
        "id": uuidv4(),  
        "subHeader": "",
        "link": "#info",
        "description": `With the aws cdk, we can plugin the deployment process by using a script to execute the command responsible 
        for deploying to our targeted environment. We can also take advantage of command line arguments to instruct how to create the infrastructure. 
        <br/>
        <br/>
        For example, we may want to specify an environment as properties passed into our cdk to instruct whether it should build a test 
        or production environment. Passing input parameters into our scripts can instruct the process how it should make decisions that are appropriate 
        for deploying to the targeted environment. `,          
        },
        {
          "id": uuidv4(),  
          "subHeader": "Stack Chronological Order",
          "link": "#rpa",
          "description": `Because we can use the CDK to  package and deploy our application to its corresponding environment,
          we should carefully consider some design principles when creating solutions.
          <br/><br/>
          One important consideration is the order of the stack execution. 
          <br/><br/>
          In order for the lambda function to work as expected, it needs to have access to our dynamodb table. This means that we should 
          deploy our stack that contains the dynamodb table first. However, we can't even create a table if we do not have the privledges to do so. We'll get
          back to that later but keep this point in the back of your mind.
          <br/><br/>
          Back to our infrastructure, assuming we have a role defined and it has the ability to do so, we could create an infrastracture stack. This would 
          be the first step to deploy 
          in before moving onto the others. The idea behind this stack is it deploys infrastructure that tends to remain the same over time.
          <br/><br/>
          Note, once the infrastructure is deployed, we may want to skip this step altogether in our pipeline or have a special pipeline 
          which constructs the infrastructure in an isolated manner. This allows us to roll-out different environments in a copy and paste fashion. The only 
          thing we’d have to consider would be the name of our project and also the environment we’d be deploying it to. 
          <br/><br/>
          With the infrastructure out of the way, the next stack we would want to consider is deploying the api stack. 
          The api stack will construct the lambda function and the the api gateway function. Both of these deployed would produce the 
          back-end api infrastructure. We would pass the same environment and project name to the stack as we did in the previous step here. 
          In fact, you could say that for the context of the pipeline these are constants which do not change.
          `                    
        },
        {
          "id": uuidv4(),  
          "subHeader": "Security Considerations",
          "link": "#security",
          "description": `This is ultimately a call an organization tends to make. Some organizations will have a more mature processes in place 
          that segregate the responsibility between devops and developer while others are more free form. Since we may want to abstract this from a 
          developer, this potentially, can live in its own isolated environment. From the order of operations, this would actually be the first 
          stack to be executed. This would setup the application role which would have the proper access across the application requirements. 
          With this role in place, the application would then be able to access different AWS resources necessary to execute in the environment. `          
        },         
        {
          "id": uuidv4(),  
          "subHeader": "Conclusion",
          "link": "#conclusion",
          "description": `In conclusion, this was just a general introduction to using AWS SDK for your CI/CD pipeline.
           It discussed, a high-level approach to defining your CI/CD pipeline using AWS CDK. This article will be used as the 
           starting point for breaking this down into more feasible steps and will run through the process of creating our application.          
           In the next article, we’ll take a more in depth look into the syntax and AWS CDK constructs to create security roles  
           and infrastructure.
          `
        }               
      ]
    }    
  }
}