/*eslint-disable*/
import React, { useEffect } from "react";
import ReactHtmlParser from 'react-html-parser';
import 'react-native-get-random-values';
import { v4 as uuidv4 } from 'uuid';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";

// sections for this page
import bg5 from "assets/img/bg5.jpg";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import blogPostsPageStyle from "assets/jss/material-kit-pro-react/views/blogPostsPageStyle.js";
import Info from "components/Typography/Info.js";
import DefaultFooter from "components/DefaultFooter/DefaultFooter.js";
import DefaultHeader from "components/DefaultHeader/DefaultHeader";


import { LazyLoadImage } from "react-lazy-load-image-component";

// data 
import {data} from "./trends-2023-data.js";
const useStyles = makeStyles(blogPostsPageStyle);

export default function Trends2023() {
  const [page, setPage] = React.useState(data);      
  const [lowRes, setLowRes] = React.useState();
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    setPage(data);          
  }, []);
  

  const classes = useStyles();  
  return (
    <div>      
     <DefaultHeader />   
      <Parallax image={page.article.backdrop}  filter="dark" small>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12} className={classes.textCenter}>
              <h1 className={classes.title}>
                {page.article.title}                
              </h1>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax> 
      <div className={classes.main}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
              <Card plain blog>
                <CardHeader image plain>
                  <a href="#">
                    <LazyLoadImage 
                      src={page.article.card.backdrop}  
                      placeholdersrc={page.article.card.placeholder}
                      alt={page.article.card.alt} />                      
                  </a>
                  <div
                    className={classes.coloredShadow}
                    style={{
                      backgroundImage: "url(" + page.article.card.placeholder + ")",
                      opacity: "1"
                    }}
                  />
                </CardHeader>
                <CardBody plain>
                  <Info>
                    <h6>{page.article.card.info}</h6>
                  </Info>
                  {page.article.card.content.map((content) => (                    
                    <div key={uuidv4()}>
                      <h3 className={classes.cardTitle} key={content.link}>
                        <a href={content.link}>
                        {content.subHeader}
                        </a>
                      </h3>
                      <h5 className={classes.description}>
                        {ReactHtmlParser(content.description)}
                      </h5>
                      {content.bullets && content.bullets.map((bullet) => (
                        <li key={uuidv4()}>
                          {bullet}
                        </li>
                      ))}
                      {content.img && 
                        <img src={content.img.src} alt={content.img.alt} />
                      }
                      {content.paragraphs && content.paragraphs.map((pg)=> (
                        <p key={uuidv4()}>{pg}</p>
                      ))}
                    </div>    
                  ))}
                </CardBody>
              </Card>
            </GridItem>              
          </GridContainer>  
        </div>
      </div>      
      <DefaultFooter props={classes}/>
    </div>    
  );
}
