import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import 'react-native-get-random-values';
import { v4 as uuidv4 } from 'uuid';
// @material-ui/icons
import BarChart from "@material-ui/icons/BarChart";
import Apartment from "@material-ui/icons/Apartment";
import List from "@material-ui/icons/List";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import SectionInterested from "./SectionInterested";
import SectionPills from "./SectionPills";
import productStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/productStyle.js";

// data
import {data} from "../landing-page-data";

const useStyles = makeStyles(productStyle);

export default function SectionProduct() {
  const classes = useStyles();
  return (
    <div className={classes.section}>      
      <GridContainer justify="center">
        <GridItem xs={12} sm={8} md={8}>
          <h2 className={classes.title}>{data.product.slogan}</h2>
          <h5 className={classes.description}>
            {data.product.headline}
          </h5>
        </GridItem>
      </GridContainer>      
      <div>
        <GridContainer>
          <GridItem xs={12} sm={4} md={4}>
            <InfoArea
              title={data.product.insights.title}
              description={data.product.insights.bullets.map((item, index) => 
                <h5 key={uuidv4()}>{item}</h5>
              )}
              icon={BarChart}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
            <InfoArea
              title={data.product.industries.title}
              description={data.product.industries.bullets.map((item) => 
                <h5 key={uuidv4()}>{item}</h5>
              )}
              icon={Apartment}
              iconColor="danger"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
            <InfoArea
              title={data.product.strategies.title}
              description={data.product.strategies.bullets.map((item) => 
                <h5 key={uuidv4()}>{item}</h5>
              )}
              icon={List}
              iconColor="warning"
              vertical
            />
          </GridItem>
        </GridContainer>
      </div>
      <h2 className={classes.title} >{data.topics}</h2>
      <SectionPills />
      <SectionInterested />            
    </div>
  );
}
