/*eslint-disable*/ import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import DefaultHeader from "components/DefaultHeader/DefaultHeader";

import landingPageStyle from "assets/jss/material-kit-pro-react/views/landingPageStyle.js";

// Sections for this page
import SectionProduct from "./Sections/SectionProduct.js";
import SectionImage from './Sections/SectionImage';

// Data for this page:
import {data} from "./landing-page-data";
import DefaultFooter from "components/DefaultFooter/DefaultFooter.js";

const useStyles = makeStyles(landingPageStyle);

export default function LandingPage({ ...rest }) {
  
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });

  const classes = useStyles();
  const index = (Math.floor(Math.random() * 3));
  const headline = data.headlines[index];
  return (
    <div>
      <DefaultHeader />
      <Parallax image={data.backdrop} filter="dark">
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
              <h1 className={classes.title}>{data.slogan}</h1>
              <h4>
                {headline}
              </h4>
              <br />              
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <SectionProduct />                              
        </div>
        <SectionImage />
      </div>
      <DefaultFooter />
    </div>
  );
}
