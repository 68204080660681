import 'react-native-get-random-values';
import { v4 as uuidv4 } from 'uuid';

export const data = {
  "brand": "Intellipoint Corporation",
  "article": {    
    "backdrop": "/img/bg9.webp",    
    "card": {      
      "content": [
        {
          "id": uuidv4(),  
          "subHeader": "Thanks For Your Interest",
          "link": "#confirmation",
          "description": `
            We are currently reviewing your information and will get back to you provided
            we have the correct contact information!
            <br/>
            <br/>
            We believe that being a good partner is more than just
            a contractual agreement. Our goal is to make a lasting impression that facilitates success. 
            Thats why client selection is so important to us.             
            <br/>
            <br/>
            We want to identify key attributes that will differentiate us from your traditional vendors.
          `
        }               
      ]
    }    
  }
}