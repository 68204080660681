import React from "react";
import Header from '../Header/Header';
import HeaderLinks from 'components/Header/HeaderLinks';
import {data} from './default-header-data';

export default function DefaultHeader(props) {
    const classes = props;
    const {page} = props;

    return (
        <Header
            brand={data.brand}
            links={<HeaderLinks dropdownHoverColor="info" page={page} />}
            fixed
            color="transparent"
            changeColorOnScroll={{
            height: 300,
            color: "info"
            }}
        />
      );
}