/* eslint-disable */
import React from "react";
// @material-ui/core components

import Hidden from "@material-ui/core/Hidden";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Footer from '../Footer/Footer';

export default function DefaultFooter(props) {    
    const classes = props;
    return(
        <Footer
        content={
          <div>
            <div className={classes.globalFooter}>
              <List className={classes.list}>                
                <ListItem className={classes.inlineBlock}>
                  <a
                    href="/company/intellipoint-corporation/about-us-page"
                    target="_blank"
                    className={classes.footerLink}
                  >
                    <Hidden mdUp>
                      About 
                    </Hidden>  
                    <Hidden smDown>
                      About Us
                    </Hidden>  
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a href="/company/intellipoint-corp/trending-in-2023-article" className={classes.footerLink}>
                    Featured
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a
                    href="http://mvargas2.com"
                    target="_blank"
                    className={classes.footerLink}
                  > 
                    <Hidden smDown>
                      The Founder
                    </Hidden>  
                    
                  </a>
                </ListItem>                                               
                <ListItem className={classes.inlineBlock}>
                  <a href="/company/intellipoint-corporation/contact-us-page" className={classes.footerLink}>
                    <Hidden mdUp>
                      Contact
                    </Hidden>  
                    <Hidden smDown>
                      Contact Us
                    </Hidden>  
                  </a>
                </ListItem>  
                <ListItem className={classes.inlineBlock}>
                  <a href="/company/intellipoint-corporation/privacy-page" className={classes.footerLink}>
                    <Hidden mdUp>
                      Privacy 
                    </Hidden>  
                    <Hidden smDown>
                      Privacy Notice
                    </Hidden>                                         
                  </a>
                </ListItem>         
              </List>
            </div>
            <div style={{marginLeft: "-15px"}}>               
              <a 
                href="http://intellipoint.cloud"
                target="_blank"
              >
                Intellipoint Corporation &copy; {1900 + new Date().getYear()}
              </a>{" "}              
            </div>
          </div>
        }
      />
    )
}