import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Muted from "components/Typography/Muted.js";
import Button from "components/CustomButtons/Button.js";

import bg10 from "assets/img/bg10.jpg";

import cardProfile1 from "assets/img/faces/card-profile1-square.jpg";
import cardProfile4 from "assets/img/faces/card-profile4-square.jpg";

import sectionImageStyle from "assets/jss/material-kit-pro-react/views/blogPostsSections/sectionImageStyle.js";
import {data} from "../landing-page-data";

const useStyles = makeStyles(sectionImageStyle);

export default function SectionImage() {
  const classes = useStyles();

  const getImageDisply = () => {
    const picIdx = Math.floor(Math.random() * 2);
    if(picIdx % 2 === 0) {
      return data.product.author.avatar;
    }     
    return data.product.author.avatar2;    
  }
  
  return (
    <div
      className={classes.section}
      style={{ backgroundImage: "url(" + bg10 + ")" }}
    >
      <div className={classes.container}>
        <GridContainer>
          <GridItem xs={12} sm={6} md={6}>
            <Card profile plain className={classes.textLeft}>
              <GridContainer>
                <GridItem xs={12} sm={5} md={5}>
                  <CardHeader image plain>
                    <a target="_blank" href="http://mvargas2.com">
                      <img src={getImageDisply()} alt="..." />
                    </a>
                  </CardHeader>
                </GridItem>
                <GridItem xs={12} sm={7} md={7}>
                  <CardBody plain>
                    <h4 className={classes.cardTitle}>{data.product.author.name}</h4>
                    <Muted>
                      <h6>{data.product.author.title}</h6>
                    </Muted>
                    <p className={classes.description}>
                      {data.product.author.description}
                    </p>
                  </CardBody>
                  <CardFooter profile plain>
                    <Button justIcon simple color="white" target="_blank" href={data.product.author.twitter}>
                      <i className="fab fa-twitter" />
                    </Button>
                    <Button justIcon simple color="white" target="_blank" href={data.product.author.facebook}>
                      <i className="fab fa-facebook-square" />
                    </Button>
                    <Button justIcon simple color="white" target="_blank" href={data.product.author.linkedIn}>
                      <i className="fab fa-linkedin" />
                    </Button>
                  </CardFooter>
                </GridItem>
              </GridContainer>
            </Card>
          </GridItem>          
        </GridContainer>
      </div>
    </div>
  );
}
