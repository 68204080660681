import 'react-native-get-random-values';
import { v4 as uuidv4 } from 'uuid';

export const data = {
  "brand": "Intellipoint Corporation",
  "article": {    
    "backdrop": "/img/bg12.webp",
    "lowRes": "/img/bg12.webp",
    "title": "The IT Trends of 2023",
    "card": {
      "info": "Technology",
      "alt": "The IT Trends In 2023",
      "backdrop": "/img/bg9.webp",
      "lowRes": "/img/bg9.webp",
      "content": [
        {
        "id": uuidv4(),  
        "subHeader": "",
        "link": "#info",
        "description": `Despite a rocky 2022 year in the tech industry and continued challenging 
        market conditions, businesses are expected to still increase their IT spending.
        The market has continued to show interest in the following areas: <br/><br/>
        <ul>
          <li>RPA (Robotic Process Automation</li>  
          <li>Datafication</li>          
          <li>Artificial Intelligence and Machine Learning</li>
          <li>Iot Standardization</li>         
          <li>CyberSecurity</li>           
        </ul>  
        For companies where Digital Transformation has already taken place, 
        larger IT budgets are expected to be generated to support the above ideas.`,          
        },
        {
          "id": uuidv4(),  
          "subHeader": "RPA (Robotic Process Automation)",
          "link": "#rpa",
          "description": `Almost every higher-level job requires a good proficiency in IT and automation these days to thrive. 
          Software can be configured to trigger responses, manipulate data, and communicate with other digital systems. 
          Examples range from things as simple as generating an auto-reply message to automating business functions and processes such as help-desk. 
          
          <strong>Attended Automation:</strong>
          Automating repetitive, manual, front-office activities that are performed on the client-side, 
          through machine training, can be automated for taking actions in real-time. Common examples range from
          an automated email response to kicking off desktop support strategies to having conversations 
          with desktop support.  
          Supporting attended automation allows users to focus on more high-value work across an organization. 
          
          <strong>Unattended Automation:</strong>
          By contrast, unattended automation doesn’t require human interaction. Rather, bots 
          do the work themselves by taking action. Because they can use triggers and schedule automated events, 
          they’re ideal for accelerating the automation of high-volume tasks across your organization.
          `          
        },
        {
          "id": uuidv4(),  
          "subHeader": "Datafication",
          "link": "#digital-transformation",
          "description": `Classification of data is a hallmark for machine learning and artificial intelligence. 
          Simply transforming everything in our life into devices or software powered by data, these classifications become 
          pivital in the role to support machines. The modification of human tasks and processes into more data-driven technology
          is the goal for these transformations. 

          <br/><br/>Data is the key to personalization which ranges from your preferences of a temperature to an individual's medical vitals.
          <ul>
            <li><strong>Data Accessibility:</strong>
              The more data we collect, the more relevant information on an individual we can present, 
              diagnose or recommend. This is a practice already used in law and journalism. Companies that require clearances 
              run a background check on a specific person, connecting them with specific places, actions and associations. 
            </li>

            <li><strong>Data Monitoring:</strong> 
              Data can be used to monitor every activity within its reach. Storing massive datasets in the cloud owned enables Datafication. Collected 
              data is then used for personalization and the level of interference is usually regulated by the law. There are governing 
              laws that limit the number of interactions associated with individuals. Some examples are GDPR and Privacy Amendment.
            </li>
            
            <li><strong>Data As Currency:</strong> 
              Cold-calling tactics is a declining strategy. What is the potential replacement and solution to this problem?
              The most valuable asset for many companies is the data associated with individuals. This information 
              positions companies to reach their targeted audience. Are companies willing to pay for this?
              <br/>              
              Absolutely. 
              <br/>
              To produce data, companies can harvest data from those who utilize their platforms.
              When users shop, scroll or interact with they're interestes, this create data, events and information.
              Data buyers are those willing to exchange it for real money, and service providers who profit from this are 
              those who sit on top of it. 
              
              The tricky aspect of this is datasets can be not only stolen, re-sold, but also used to commit cybercrimes on users, 
              whose records are gathered.
            </li>         
          </ul>
          `          
        },
        {
          "id": uuidv4(),  
          "subHeader": "Artificial Intelligence and Machine Learning",
          "link": "#ai",
          "description": `Machine Learning is a subset of Artificial Intelligence. It also happens to be deployed in all kinds of industries, 
          creating a huge demand for skilled professionals. AI, machine learning, and automation is expected to create 10,177,777,777 jobs by 
          the end of 2025. Jobs functional areas are expected to come from areas like robot monitoring professionals, data scientists, 
          automation specialists, and content curators, making it another new technology trend you must keep in mind.
          <br/>
          <br/>
          Artificial Intelligence has various categories and the following describe each of them:
          <ul>
            <li>
              <strong>Reactive Machines</strong><br/>
              Reactive Machines are the oldest form of AI technologies that have been in place for many years now. These systems 
              mimic the ability to respond to circumstances in a fashion similar to the human mid. These machines can not use previously gained 
              expereince to inform their present actions and thus do not have the ability to learn. 
            </li>
            <li>
            <strong>Limited Memory</strong>
              On the other hand,Limited memory machines are machines that, inaddition to having capabilities of purely reactive machines, 
              also posses the ability to learn form historical data to make decisions. Nearly all existing applications that we use in 2023 are 
              Limited Machines. That is, they present themselves in tehf orm of deep learning, trained with large data sets and store their memory to 
              form a reference model for solving future problems.
            </li>
          </ul>   
          <br/>
          The areas of focus for the coming years will be transition react machines to limited memory based one's. This historical information will be used for future generations
          to serve newer models and make more intelligent decisions as time passes on. This gives the future machine more precise datasets to base future decisions on.
          Over time, as data is continously ingested, it will be better understand a point of interest.
          `          
        },
        {
          "id": uuidv4(),  
          "subHeader": "IoT Standardization",
          "link": "#iot",
          "description": `
            With the success of so many smart devices, one of the challenges that have plagued the IoT communities are the differences in exchange protocols
            and standardizations across many proprietary providers. This is the challenge presenting the community in 2023. In order to provide a more rich and diverse IoT 
            experience, developing global protocols and standards will be the primary focus. 
                      
            With this, IoT will create a massive network of interconnected devices that will provide greater security, efficiency, and decision-making 
            for businesses as more data is collected and analyzed.
            <br/>
            <br/>
            IoT technology collects and interprets the data collected by machines and then provides information about the process to improve efficiency, 
            save costs in production, detect new business opportunities and consumer trends or promote product innovation.            
          `          
        }, 
        {
          "id": uuidv4(),  
          "subHeader": "Cybersecurity",
          "link": "#security",
          "description": `If all of the above information sounds a bit scary to you, it shouldn't surprise you that others feel the same way. Because with all of this information comes a huge trade-off which is how the information is used. 

          Thus, Cybersecurity must continue to play an ever-increasing role in protecting company data. The practice of ensuring confidentiality, integrity, and availability of information is becoming more of a prevailing issue than historically it ever has been. 
          
          Ensuring the protection of an individual's identity is now more important than ever because this same information could be used inadvertently against them if falling into Criminal Use. Companies will be held liable and this is why CyberSecurity is such an important
          challenge we face in the current age.`
        }               
      ]
    }    
  }
}