import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import TrendingUp from "@material-ui/icons/TrendingUp";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Info from "components/Typography/Info.js";
import Success from "components/Typography/Success.js";
import Danger from "components/Typography/Danger.js";

import bg5 from "assets/img/bg5.jpg";
import blog5 from "assets/img/examples/blog5.jpg";
import blog6 from "assets/img/examples/blog6.jpg";
import {data} from "../landing-page-data";

import sectionInterestedStyle from "assets/jss/material-kit-pro-react/views/blogPostsSections/sectionInterestedStyle.js";

import { LazyLoadImage } from "react-lazy-load-image-component";

const useStyles = makeStyles(sectionInterestedStyle);

export default function SectionInterested() {
  const classes = useStyles();
  const {useCaseStudies} = data.product;

  function renderCategory(category, index){
    switch(index) {
      case 0:
        return <Info><h6>{category}</h6></Info>                
      case 1:
        return <Success><h6>{category}</h6></Success>
      default:
        return <Danger><h6>{category}</h6></Danger>      
    }
  }

  return (
    <div className={classes.section}>
      <h3 className={classes.title + " " + classes.textCenter}>
        {data.product.useCaseStudies.title}
      </h3>
      <br />
      <GridContainer>        
        {useCaseStudies.moreStudies.map((caseStudy, index) => 
          <GridItem key={index} xs={12} sm={4} md={4} lg={4} xl={4}>
            <Card plain blog>
              <CardHeader image plain>
                <LazyLoadImage 
                  src={caseStudy.backdrop}  
                  placeholdersrc={caseStudy.placeholder}
                  alt={caseStudy.alt} />                
                <div
                  className={classes.coloredShadow}
                  style={{
                    backgroundImage: "url(" + bg5 + ")",
                    opacity: "1"
                  }}
                />
              </CardHeader>
              <CardBody plain>
                {renderCategory(caseStudy.category, index)}    
                <h4 className={classes.cardTitle}>
                  <a href={caseStudy.learnMore} alt={caseStudy.lmDesc}>
                    {caseStudy.title}
                  </a>
                </h4>
                <p className={classes.description}>
                  {caseStudy.intro}
                  <a href={caseStudy.learnMore} alt={caseStudy.lmDesc} target="_blank"> Read More </a>
                </p>
              </CardBody>
            </Card>
          </GridItem>
        )}                    
      </GridContainer>
    </div>
  );
}
