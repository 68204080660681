import 'react-native-get-random-values';
import { v4 as uuidv4 } from "uuid";

export const data = {
  brand: "Intellipoint Corporation",
  article: {
    backdrop: "/img/bg13.jpg",
    title: "About Us",
    description: `Intellipoint Corporation is Always Striving for Your Success.`,
    heading: {
      id: uuidv4(),
      subHeader: `Since 2012, we have been helping our customers, partners and clients to achieve true prosperity.
        Over the years, we've adapted and evolved with the ever changing demands of business. 
        <br/><br/>
        We've mastered our craft in specializing in product architecture, development and delivery.          
        `,
      link: "#info",
    },
  },
  product: {
    slogan: "Positioning Business For Success",
    headline:
      "In an ever-changing world of technologies, trends and systems, businesses are faced with making critical decisions with minimal time, here's where we can help:",
    insights: {
      title: "Specialization",
      bullets: [
        "Digital Transformation and Modernization",
        "Cloud Solution Architecture & System Design",
        "Data Analytics and Insights",
        "Full-Stack Application Development",
      ],
    },
    industries: {
      title: "Industries",
      bullets: [
        "Hospitality, Travel and Leisure",
        "Higher Education and Standardized Testing",
        "Industrial and Renewable Energy",
        "Financial Accounting and Investment",
      ],
    },
    strategies: {
      title: "Process",
      bullets: [
        `Adapt to Rapid Changes with Agile`,
        `Automate Repeatable Processes`,
        `Create cost-effective, highly scalable and robust solutions`,
        `Define purpose-driven process`,
      ],
    },
    author: {
      avatar: "img/avatar.webp",
      facebook: "https://www.facebook.com/archAngel1963/",
      twitter: "https://twitter.com/mvargas2015",
      linkedIn: "https://www.linkedin.com/in/agentvargas2012r/",
      name: "Michael Vargas",
      title: "Founder of Intellipoint Corporation",
      description:
        "We're dedicated to the success of your vision, leadership and business. ",
    },
    useCaseStudies: {
      title: "Tech Discussions",
      cards: [
        {
          id: uuidv4(),
          backdrop: "/img/bg1.webp",
          category: "Technologies",
          cta: "Read Article",
          title: "AWS CDK V2.0",
          description: `The new AWS CDK has been released and we've gotten a chance to work with it. In this article, we\'ll take a look into the CDK
              ecosystem and how to integrate into a given CI/CD pipeline.`,
          href: "/aws-cdk-v2",
        },
        {
          id: uuidv4(),
          backdrop: "/img/bg11.jpg",
          category: "Technologies",
          cta: "Read Article",
          title: "Using ChatGPT",
          description: `A lot of news has circled around using ChatGPT. Everything from automating conversational responses to 
              passing the bar, this technology is gaining alot of attention. Is it something you should be considering for your business too?`,
          href: "/using-chat-gpt",
        },
        {
          id: uuidv4(),
          category: "Technologies",
          backdrop: "/img/bg9.webp",
          cta: "Read Article",
          title: "Industry Trends in 2023",
          description: `With the dynamic nature of IT and the continually evolutionary changes that paving the future for technological driven advances,
              we're are we heading in the coming year? Here's what we think.`,
          href: "/trending-in-2023",
        },
      ],
      moreStudies: [
        {
          category: "System Design",
          backdrop: "/img/bg6.jpg",
          title: "Designing Single Table Access Patterns",
          alt: "Designing Single Table Access Patterns",
          intro:
            "In this article, we'll take a closer look into designing access patterns using the single table strategy. This has been a pretty hot topic in the industry over the last few years because it positions your application to use NoSQL tables in a way that promotes cost-effective savings and fast read/write access.",
          learnMore: "/single-table-access",
        },
        {
          category: "Architecture",
          backdrop: "/img/bg3.jpg",
          title: "Architecting 12 Factor Apps",
          alt: "Architecting 12 Factor Apps",
          intro:
            "We’ll apply the 12 factor app methodology to our serverless app running on AWS. By following this strategy, we’ll produce a powerful, robust system that will meet all the criteria required for designing modern applications that run on the cloud using industry best practices and system-design principles...",
          learnMore: "/applying-12-factor-app-principles-page",
        },
        {
          category: "Development",
          backdrop: "/img/bg5.jpg",
          title: "Creating Dynamic React Components",
          alt: "Creating Dynamic React Components",
          intro:
            "Let's take a closer look into developing React components that load data dynamically. This will allow us to follow the principles of DRY and create components which we can reuse across the application in a manner that is a low-code effort. Let's get started with this...",
          learnMore: "/creating-dynamic-react-components",
        },
      ],
    },
  },
  services: {
    title: `Innovating Tomorrows Future Today`,
    description: `Investing in tomorrow's technology,
    our development team is constantly learning game changers like 
    Artificial Intelligence and Machine Learning, IoT and Cybersecurity.
    These are just a few things we can bring to the table.`,
    content: [
        {
            title: `UX and Design`,
            backdrop: '/img/bg12.webp',
            description: `Our creative team positions your product in a 
            empathetic and thought-provoking perspective. Considering accessibility,
            an intuitive user experience and applying industry best practices to create 
            beautiful and responsive sites that render across different mediums and platforms.
            Let's make a great product.
            `
        },
        {
            title: `Architecture`,
            backdrop: '/img/bg8.webp',
            description: `Taking a product from conception to completion requires state-of-the-art thinking
            in order to leverage gaps and bridges that have yet been discovered. We bring architecture experience
            to domains in uncharted territory. The key to sustainable, powerful and scalable apps
            is great solution architecture.`
        },
        {
            title: `Development`,
            backdrop: '/img/bg7.jpg',
            description: `Over the last few years, systems have been becoming more and more complex.
            Accurate system requirements that truly capture a target audience and implementation 
            of that product are perhaps the most pivitol point of a products lifecycle. The key to 
            a performant, robust and availble application is development.`
        }
    ]
  },
};
