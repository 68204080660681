import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import FormatAlignLeft from "@material-ui/icons/FormatAlignLeft";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";

import sectionPillsStyle from "assets/jss/material-kit-pro-react/views/blogPostsSections/sectionPillsStyle.js";
import {data} from "../landing-page-data";

const useStyles = makeStyles(sectionPillsStyle);

export default function SectionPills() {
  const classes = useStyles();

  function renderMainTopic(card) {
     return (
      <GridItem key={card.id} xs={12} sm={12} md={12}>
          <Card
            raised
            background
            style={{ backgroundImage: "url(" + card.backdrop + ")" }}
          >
            <CardBody background>
              <h6 id="featured-article" className={classes.category}>{card.category}</h6>
              <a href={card.href}>
                <h3 className={classes.cardTitle}>
                  {card.title}
                </h3>
              </a>
              <p className={classes.category}>
                {card.description}
              </p>
              <Button round href={card.href} color="warning">
                <FormatAlignLeft className={classes.icons} /> {card.cta}
              </Button>                              
            </CardBody>
          </Card>
        </GridItem>);
  }

  function renderSubHeaders(card) {
    return (
    <GridItem key={card.id} xs={12} sm={6} md={6}>
      <Card
        raised
        background
        style={{ backgroundImage: "url(" + card.backdrop + ")", width: "100%" }}
      >
        <CardBody background>
          <h6 className={classes.category}>{card.category}</h6>
          <a target="_blank" href={card.href} >
            <h3 className={classes.cardTitle}>
            {card.title}
            </h3>
          </a>
          <p className={classes.category}>
            {card.description}
          </p>
          <Button round href={card.href} target="_blank" color="danger">
            <FormatAlignLeft className={classes.icons} /> {card.cta}
          </Button>
        </CardBody>
      </Card>
    </GridItem>);
  }


  function renderCardsDynamically(todaysPresentation) {        
    return todaysPresentation.map((card, index) => {
      if(index === 0) {
        return renderMainTopic(card);
      } else {        
        return renderSubHeaders(card);
      }     
    })    
  }
  
  const length = data.product.useCaseStudies.cards.length;
  const idx = Math.floor(Math.random() * length);
  const todaysPresentation = [];
  todaysPresentation.push(data.product.useCaseStudies.cards[idx]);
  todaysPresentation.push(...data.product.useCaseStudies.cards.filter((card) => card != data.product.useCaseStudies.cards[idx]));

  return (
    <div className={classes.section}>      
      <GridContainer>        
          {renderCardsDynamically(todaysPresentation)}                        
      </GridContainer>
    </div>
  );
}
