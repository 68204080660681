/*eslint-disable*/
import React from "react";

// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";


import SectionDescription from "views/AboutUsPage/Sections/SectionDescription.js";
import SectionServices from "views/AboutUsPage/Sections/SectionServices.js";

import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";
import DefaultFooter from "components/DefaultFooter/DefaultFooter";
import {data} from './Sections/about-us-data';
import DefaultHeader from "components/DefaultHeader/DefaultHeader";

const useStyles = makeStyles(aboutUsStyle);

export default function AboutUsPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
      <DefaultHeader />
      <Parallax image={data.article.backdrop} filter="dark" small>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem
              md={8}
              sm={8}
              className={classNames(
                classes.mlAuto,
                classes.mrAuto,
                classes.textCenter
              )}
            >
              <h1 className={classes.title}>{data.article.title}</h1>
              <h4 className={classes.title}>
                {data.article.description}
              </h4>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <SectionDescription />
          <GridContainer justify="center">
            <GridItem xs={12} sm={8} md={8} >
              <h3 className={classes.title2}>{data.product.slogan}</h3>
              <h5 className={classes.description}>
                {data.product.headline}
              </h5>
            </GridItem>
          </GridContainer>
          <SectionServices />                            
        </div>
      </div>
     <DefaultFooter />
    </div>
  );
}
