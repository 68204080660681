import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";


import servicesStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/servicesStyle.js";
import {data} from './about-us-data';


const useStyles = makeStyles(servicesStyle);

export default function SectionServices() {
  const classes = useStyles();
  return (
    <div className={classes.services}>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(
            classes.mlAuto,
            classes.mrAuto,
            classes.textCenter
          )}
        >
          <h2 className={classes.title}>{data.services.title}</h2>
          <h5 className={classes.description}>
            {data.services.description}
          </h5>
        </GridItem>
      </GridContainer>
      <GridContainer>
        {data.services.content.map((service, index) =>   
          <GridItem key={index} xs={12} sm={4} md={4}>
            <Card
              raised
              background
              style={{ backgroundImage: "url(" + service.backdrop + ")" }}
            >
              <CardBody background>                
                <h3 className={classes.cardTitle}>
                  {service.title}
                </h3>                
                <p className={classes.category}>
                  {service.description}
                </p>                
              </CardBody>
            </Card>
          </GridItem>
        )}
      </GridContainer>
    </div>
  );
}
