import 'react-native-get-random-values';
import { v4 as uuidv4 } from 'uuid';

export const data = {
  "brand": "Intellipoint Corporation",
  "article": {    
    "backdrop": "/img/bg.jpg",
    "title": "Designing Single Table Access Patterns",
    "card": {
      "info": "System Design Methods",
      "alt": "analytical dashboard",
      "backdrop": "/img/jsortino.jpg",
      "content": [
        {
        "id": uuidv4(),  
        "subHeader": "What is Single Table Access?",
        "link": "#info",
        "description": `In this article, we'll take a closer look into designing access patterns using the single table strategy. This has been a pretty hot topic in
        the industry over the last few years because it positions your application to use NoSQL tables in a way that promotes cost-effective savings and fast read/write
        access. By identifying your access patterns, you will equip your application to perform at great speed and produce meaningful results with minimal overhead. 
        Unlike traditional relational databases (RDBMS) systems, NoSQL tables are key-value databases built for performing extremely high volumes of transactions. 
        They do not support relational queries. This means you won't find SQL JOINS when accessing a NoSQL Table. Joins are not typically supported because they 
        come at an expensive cost. A trade-off comes with using NoSQL vs SQL table. Some popular choices of NoSQL tables are MongoDB and DynamoDB.`,          
        },
        {
          "id": uuidv4(),  
          "subHeader": "DynamoDB",
          "link": "#dynamoDb",
          "description": `Amazon DynamoDB is a fully managed proprietary NoSQL database service that supports key-value and document data structures and is 
          offered by Amazon.com as part of the Amazon Web Services portfolio. DynamoDB exposes a similar data model to and derives its name from Dynamo, 
          but has a different underlying implementation. It is used in Financial Services, Marketing and Advertising, Retail, Higher Education, Hospital and Health, Telecom 
          among other industries.`          
        },
        {
          "id": uuidv4(),  
          "subHeader": "Normalized Data VS Denormalized Data",
          "link": "#",
          "description": `          
          <strong>SQL Normalization:</strong>
          <br/>
          One of the biggest mental transitions from RDBMS to NoSQL is normalization. Typically, with relational databases, we want to have a high degree of normalization.
          This means that we want to avoid duplicate data being present in any record, table or row. We also want to ensure our relational integrity using the concept of foreign keys and constraints.
          Using this ensures that our data state is accurate and avoids stale and orphaned records. Orphaned Records are those that do not have any parent or sibling relationship or are thus lost.
          
          <br/><br/>
          <strong>NoSQL Denormalization:</strong> 
          <br/>
          As mentioned before, using NoSQL comes with a trade off. We trade-off normalized data with speed to avoid slow I/O. The price we pay for quick retrieval and writes is normalization.
          While normalization is a goal, it does not scale very well. As a result, when designing tables using NoSQL, you should flip this concept on its head entirely. 
          The more unstructured or denormalized the table, typically, the better. That is because we want to combine multiple tables into a single table even 
          at the cost of duplicating records, or repeating rows in some cases. Obviously, we want to avoid this if it is possible and do this blindly. This is where the single table access pattern can help us understand the most valuable information to retrieve.
          `          
        },
        {
          "id": uuidv4(),  
          "subHeader": "Example",
          "link": "#example",
          "description": `With the theory out of the way, let's take a look at a working example of taking a normalized set of relational tables and applying the concept of Single Table access.
          Let's take a simple use case scenario, a contact list. As shown below, each contact can have multiple phone numbers and multiple addresses.`,
          "img": {
            "src": "/img/normalized-data.svg",
            "alt": "Example Codebase"
          },
          "paragraphs": [
            `</br>Currently, on the Contact table, we have foreign Key (FK) which refers to a list of Addresses. How would we model this relationship using NoSQL? </br> 
            The answer is...you guessed it...denormalization. We would take each record in the child table and combine it with the parent table. Thus, producing
            three records in this case. 
            `
          ],
        },        
        {
          "id": uuidv4(),  
          "subHeader": "Denormalized Data",
          "link": "#demData",
          "description": `With the denormalization out of the way, we now have a working table like this:`          ,
          "img": {
            "src": "/img/denormalized-data.jpg",
            "alt": "Example Codebase"
          },
          "paragraphs": [
            `Since we don't have a foreign relationship, we can now drop the foreign keys. What we end up with at this point is a record repeated three times.
            The data is at a point in time where we can start to think about the different ways we would want to retrieve it.`
          ],
        }, 
        {
          "id": uuidv4(),  
          "subHeader": "The Importance of Designing Access Patterns",
          "link": "#analytics",
          "description": `<strong>The most important part of this process is to understand and identify the correct way to retrieve your data. </strong> Making this mistake is a costly one
          because typically NoSQL tables have extremely high amounts of data. Having to change your access pattern means, you need to re-index all of it. Alternatively,
          you could create a different way of querying your data but particularly with DynamoDB, every index added to a table, becomes a replica of that data. This means
          if you have two indexes, you are essentially using two copies of your data. Adding more GSI's does not always guarantee a fix because, at this time, you can only 
          add up to 5 GSIs in DynamoDB. Because of these limitations, you should consider carefully and understand how that data is going to be retrieved. Let's review our example in more detail.`          
        },
        {
          "id": uuidv4(),  
          "subHeader": "Identifying Access Patterns",
          "link": "#access",
          "description": `While in this scenario it may be obvious what choices should be made, be sure to take the time to apply this exercise, the choices may not be so obvious when it comes to query selection.
          Using the questioning method, we ask ourselves what data is most likely to be needed. This could come from requirements and acceptance criteria or our judgment.
          <br/><br/>
          Question's to ask:
          <ul>
            <li>How can I retrieve information for a contact?</li>
            <li>How can I deal with contacts having the same name?</li>
            <li>How can I deal with contacts that have a name change?</li>
          </ul>            
          Potential Answers:
          <ul>
            <li>By Phone Number</li>
            <li>By Email Address</li>
            <li>By Address</li>
          </ul>
          Remember, we want quick retrieval speed and access. To query a record, we should make use of query that uses a primary key and a sorting key. 
          These keys are typically defined as PK and SK in schemas. To store multiple records, we need to ensure the combination of the PK and SK is what
          sets it apart and ensures its uniqueness across the table. 
          `
        },
        /**
         * START HERE.........
         */
        {
          "id": uuidv4(),  
          "subHeader": "Defining Our Primary and Sorting Key",
          "link": "#sk",
          "description": `
          For the sake of this example, we'll discuss the process of creating the primary and sorting key from the information listed above.
          <h4>Primary Key</h4>          
          Our first step is to identify the primary key. Looking at the above, we might be tempted to use the email address as our primary key and while
          that's possible, a user may not register an email address for fear of being sent unwanted emails. Also, for social media integration support,
          we may not have this information necessarily available. Let's assume for the solution, these two statements hold true. Our system is primarily focused
          on capturing information available without the need for an email address.            
          <br/>
          <br/>
          An alternative approach would be using the user's first and last name as the primary key in this context. This would identify the individuals that
          share a common name. It is also most likely that from a data-entry standpoint, a client would enter this information first before moving on to any other.
          <br/>
          <br/>
          For <strong>Gustave Eiffel</strong>, we could use the following format as a potential option.
          <pre>PK: Gustave-Eiffel</pre>          
                    
          <h4>Sorting Key</h4>          
          For our sorting key, we want to take advantage of a composition key. A composition key is one where bits and pieces of the key are composed of different values.
          This will come in handy when we are using retrieval access depending on the information available or defined. <strong>Another important aspect of defining your sk
          is understanding the precedence of each part of the information available.</strong>
          <h5>Locating A User by Address</h5>          
          We have the following data:
          <ul>                        
            <li>street</li>
            <li>city</li>
            <li>country</li>            
          </ul>
          It would make logical sense that to locate a user, we would enter a physical address as the secondary way of retrieving this information to identify 
          a given user. Therefore, the street of an address would probably rank the highest on this list. 

          <pre>SK: street</pre>

          To combine several parts of information, we can make use of a delimiter to denote the different components of the key we want to retrieve based on what 
          information is available. We decide to use the '#' symbol which has become a common practice.

          <pre>SK: street#</pre>                    
          <br/>
          
          With these eliminated from our list, we now have the following attributes to consider:
          <ul>                                                
            <li>city</li>
            <li>country</li>            
          </ul>
          We can pretty much use each of these to complete our key since from top to bottom. A consideration, as we use more of the
          tokens, the sorting key becomes more specific:
          <pre>SK: street#city#country </pre>
          That said, how do we deal with whitespaces or special characters? 
          <br/> 
          We could perform a minor transform to use snake-case to remove whitespaces and replace them with hyphens and store special characters in ASCII. 
          <br/>
          <br/>
          Here is an example:
          <ul>            
            <li>Name: Gustave Eiffel</li>                          
            <li>Street Address: 5th Avenue</li>
            <li>City: Paris</li>
            <li>Country: France</li>
          </ul>
          <pre>
            PK: Gustave-Eiffel 
            SK: 5th-Avenue#Paris#France
          </pre>          
          What does this accomplish though? Why would this be useful?
          </br>
          Well, first we can make control a query through the SK components to widen or narrow a query result set.
          
          If we wanted to know all the individuals with a name of Gustave we would simply query like this:
          <pre>
          PK: Gustave-Eiffel
          SK: 5th-Avenue
          </pre>
          This would show all accounts that have a name with Gustave Eiffel that live on 5th Avenue. That said, this would also match
          individuals who live in other 5th Avenues around the world such as New York.                    
          <strong>Notice, we are using the same PK and SK here to retrieve different results based on the data we supply to the query.</strong>
          We accomplish this by using the SK with the PK to identify records we're interested in returning.  
          <br/>
          <br/>

          If we wanted to narrow the scope down a bit further, we can include the city as part of the search:
          <pre>
          PK: Gustave-Eiffel
          SK: 5th-Avenue#Paris
          </pre>          
          <br/>
          <br/>

          We can certainly narrow this query even further by providing the country:
          <pre>
          PK: Gustave-Eiffel
          SK: 5th-Avenue#Paris#France
          </pre>
          This query is the most specific because unlike the previous permutations, we are specifying the country which reduces the possibility of more than one match. <br/>
          Fun fact, there are currently 48 cities around the world that share the name Paris so having more than one result can happen if we don't specify a country.
          <br/>
          <br/>

          Let's review the queries we posed at the beginning of this section.     
          Question's to ask:
          <ul>
            <li>How can I retrieve information for a contact?</li>
            <li>How can I deal with contacts having the same username?</li>
            <li>How can I deal with contacts that have changed names?</li>
          </ul>            
          <strong>Answer 1:</strong> We can retrieve the contact information using the contact's user name. 
          <br/>
          <br/>
          <strong>Answer 2:</strong> Since we are also using the sorting key in combination with the PK, we can use the contacts name and address to distinguish between
          common names between different users.
          <br/>
          <br/>
          <strong>Answer 3:</strong> In cases where a name change occurs, we need to figure out how to still retrieve a record. The contact could simply update their last name on the front-end system.
          On the back-end, we would still query thier maiden name and perform an update on the sorting key. This would create a new record in the database but retain the data associated with the record.
          This could be accomplished through a preferences user interface where the user can perform actions such as name changes and update addresses since these things tend to change over time.           
          <br/>
          <br/>                    
          <strong>With all of this design out of the way, we are almost ready to create our table. Remember, we haven't implemented anything and we still want to think through a few more scenarios before doing so.</strong>
          <br/>
          Here is our potential table so far:`,
          "img": {
            "src": "/img/single-table-access.jpg",
            "alt": "Single Table Data"
          }                    
        },
        {
          "id": uuidv4(),  
          "subHeader": "Identifying More Access Patterns",
          "link": "#patterns",
          "description": `Another popular search method would be to use the phone number associated with the contact. Ultimately, it comes down to the application requirements
          that define the best way to identify access patterns. If we're building a Call Center application then you could argue we don't need the previous access pattern at all.
          If, however, we have a situation where the user is unable to locate a previous address in the system, we still need a way to retrieve the users information as an alternative.
          <br/><br/>
          We have the decision to make, we can either add a GSI or store the information differently. Let's use the flexibility of the PK and SK to accomplish this without
          introducing a new GSI. 
          <br/><br/>
          To search by a user, we could use the phone number as the primary key in this context.
          <pre>
            PK: 123456789 
          </pre>
          This would automatically narrow our search results to a very specific set of individuals. 
          <br/><br/>
          For our SK, we can leverage the contact's name and address. 
          <pre>
            SK: name#street#city#country
          </pre>
          Here is the current state of our design and the information associated with it.`,
          "img": {
            "src": "/img/different-access.jpg",
            "alt": "Single Table Data"
          }          
        },
        {
          "id": uuidv4(),  
          "subHeader": "Unstructured Data",
          "link": "#unstructured",
          "description": `If you've been keeping an eye on the denormalization process, you'll notice something else, our data is now unstructured vs a structured
          record set. This is another fundamental difference between the two types of databases. In RDMS, we are
          guaranteed to have attributes defined on a record with constraints whereas in NoSQL, if it is not part of the PK or Sk, nothing is guaranteed. 
          <br/>
          <br/>
          This does come with some advantages because with the naming conventions we've applied to our above table, as the name implies, we can persist different types
          of entities as a result of the very nature of unstructured data so long that it satisfies the PK and SK requirement. 
          <br/></br>
          Another important note, because we've defined our PK and SK as generic types, notice we did not define the PK as "Name", we can store different types of 
          entities within the same table. We can even use different retrieval access patterns while still leveraging the same table.

          Again, we review the original questions we posed:          
          <ul>
            <li>How can I retrieve information for a contact?</li>
            <li>How can I deal with contact's having the same username?</li>
            <li>How can I deal with contact's that have changed names?</li>
          </ul>            
          <strong>Answer 1:</strong> We can retrieve the contact information using the contacts\` phone number. 
          <br/>
          <br/>
          <strong>Answer 2:</strong> Since we are also using the sorting key in combination with the PK, we can use the contacts name and address to distinguish between
          common names between different users but this case is highly unlikely.
          <br/>
          <br/>
          <strong>Answer 3:</strong> We solve this challenge by providing an interface that allows a user to update the SK. In other words, we have a UI screen where a user
          can update their preferences. From the back-end, we perform an update on the record and capture the information associated with the user on the new information.            
          <br/>
          <br/>
          <strong>Up to this point, we've only considered storing a single entity but what happens when we store multiple types of entities?</strong>
          <br/><br/>
          <strong>Another question, what happens if we have similar data where it isn't so straightforward to distinguish between similar entities?<br/>For example, we now have two types of contacts that contain similar data. How can we distinguish between them?</strong>
          <br/><br/>
          Well, like the very nature of storing data, we need a way to identify a type of record. A common practice is to store an <strong>entityType</strong> (string)
          value which represents the record type. By doing this makes it easier for consuming application to query data properly using filtering on the 
          specific type of entity being requested.`,
          "img": {
            "src": "/img/entity-types.jpg",
            "alt": "Storing Entity Types"
          },          
        },                
        {
          "id": uuidv4(),  
          "subHeader": "Supporting Books and Other Entities",
          "link": "#types",
          "description": `Because we've defined our Primary and Sorting Keys as generic types, we can <strong>reuse</strong> these columns to support
          storing more than one particular entity. We can repeat the same process for storing books as an example. 
          </br>
          </br>
          Doing this, we can define our Primary Key with the title of the book. 
          <pre>
            PK: Moby-Dick
          </pre>
          Next, using the questioning method, we can identify the most valuable information from a book. Typically, the author or publishing company would rank highest, 
          followed by the edition, copy format and language.
          <br/>
          <br/>
          We end up with this:
          <pre>
            SK: author#edition#format#language
          </pre>
          Let's take a look at our design with these different entities co-existing in the same table.
          `,
          "img": {
            "src": "/img/me.jpg",
            "alt": "Adding More Entites"
          },
          paragraphs:[
            `<br/>A few things to check out, each entity will make use of the metadata that applies to it. You can verify that book-type
            records do not always make use of an email attribute. For our contacts by phone object, it never uses the age group, although it has the flexibility to do so.<br/><br/> 
            The key point here is that we did not change the primary key nor did we need to change the sorting key columns. Rather, we simply construct a key that fits the needs of our access pattern for a given entity.<br/><br/>
            Following the process, we ended up with these entity sk and pk mappings. We can now support over 10 queries without changing our table.
            <br/>
            <br/>
            It is possible to query: 
            <ul>             
              <li>Books by author</li>
              <li>Books by publishing type</li>
              <li>Books by language</li>
              <li>Books by page length</li>
              <li>Contacts by name and phone number</li>
              <li>Contacts by street</li>
              <li>Contacts by city</li>
              <li>Contacts by country</li>
            </ul>`            
          ]
        },
        {
          "id": uuidv4(),  
          "subHeader": "Conclusion",
          "link": "#conclusion",
          "description": `Hopefully, you can see how powerful this approach is and how quickly you can scale out a single table using this method.
          When volumes of data become a problem and you need fast access, this is a great way to have an excellent and performant table that can
          handle changes in a robust manner.
          <br/><br/>
          Now that we've thrown several variations of this, we're ready for implementation.<br/<br/>
          Ultimately, theneeds of your application should dictate the
          "right" way to access your data.`
        }  
        /**
         * END HERE.........
         */   
      ]
    }    
  }
}